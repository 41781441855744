import React, { Fragment, Component } from 'react';
import jsPDF from 'jspdf';
import ReactToPrint from "react-to-print";

import { InvoicePdfDetailsComponent } from './InvoicePdfDetailsComponent';
export class InvoicePdfComponent extends Component {
    static displayName = InvoicePdfComponent.name;
    constructor(props) {
        super(props);
        this.state = {
            invoiceId: props.location.state.invoiceId,
            invoiceTypeId: props.location.state.invoiceTypeId,

        }
    }
    // printDocument = () => {
    //     const pdfContentEl = document.getElementById('divToPrint');
    //     const doc = new jsPDF();
    //     doc.html(pdfContentEl).save('test.pdf');
    // }
    printDocument() {
        const input = document.getElementById("divToPrint");
        const pdf = new jsPDF('p', 'mm', 'a4'); //unit: "px", format: "letter", userUnit: "px" 
        pdf.html(input, { html2canvas: {  scale: 0.17} }).then(() => {
            pdf.save("Invoice.pdf");
        });
        // scale: 0.10

        // pdf.html(input, { html2canvas: { scale: 0.8 },
        //     pagesplit: true,
        //     callback: () => {
        //       html2canvas(SELECTOR_HERE, {
        //         useCORS: true
        //       }).then((canvas) => {
        //         const img = new Image();
        //         img.src = canvas.toDataURL('image/png');
        //         img.onload = () => {
        //           pdf.addImage(img, 0, 0, pdf.internal.pageSize.width, 200);
        //           pdf.save(`download.pdf`);
        //         };
        //       });
        //     }
        // });
    }
    setComponentRef = (ref) => {
        this.componentRef = ref;
    };

    reactToPrintContent = () => {
        return this.componentRef;
    };
    reactToPrintTrigger = () => {
        return <button className='btn btn-success'  >Print Invoice</button>;
    };
    render() {
        return (
            <Fragment>
                <div className='card bg-filter'>
                    <div className='card-body text-center'>
                        <button id='button-pdf' className='btn btn-success' onClick={this.printDocument}>Export to PDF</button>&nbsp;&nbsp;
                        <ReactToPrint
                            content={this.reactToPrintContent}                            
                            documentTitle="Invoice"
                            removeAfterPrint
                            trigger={this.reactToPrintTrigger}
                        />
                    </div>
                </div>
                <div className='card'>
                    <div className='card-body'>                        
                        <div id="divToPrint">
                        
                            <InvoicePdfDetailsComponent ref={this.setComponentRef} dataSource={this.state} />
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}